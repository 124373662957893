import { demoProjectTypes } from "./demoProject.types";

const INITIAL_STATE = {
    error: "",
    demoProjectData: "",
    loading: true,
};

const demoProjectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case demoProjectTypes.DEMO_PROJECT_ACTION_START:
            return {
                ...state,
                loading: true,
            };
        case demoProjectTypes.DEMO_PROJECT_GET_SUCCESS:
            return {
                ...state,
                demoProjectData: action.payload,
                error: "",
            };
        case demoProjectTypes.DEMO_PROJECT_GET_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        case demoProjectTypes.DEMO_PROJECT_ACTION_END:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export default demoProjectReducer;
