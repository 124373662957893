import { propertyAdsTypes } from "./propertyAds.types";

const INITIAL_STATE = {
    error: "",
    propertyAdsData: "",
    loading: true,
};

const propertyAdsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case propertyAdsTypes.PROPERTY_ADS_ACTION_START:
            return {
                ...state,
                loading: true,
            };
        case propertyAdsTypes.PROPERTY_ADS_GET_SUCCESS:
            return {
                ...state,
                propertyAdsData: action.payload,
                error: "",
            };
        case propertyAdsTypes.PROPERTY_ADS_GET_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        case propertyAdsTypes.PROPERTY_ADS_ACTION_END:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export default propertyAdsReducer;
